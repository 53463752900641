@use 'sass:math';
@use 'sass:color';

$link-hover-decoration: none;
$input-border-color: #cacaca !default;
$input-border-width: 1px !default;
$convos-header-height: 64px !default;

$input-focus-border-color: color.adjust($input-border-color, $lightness: -40%) !default;
$section-border-color: #d5d5d5 !default;
$modal-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
$box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1);
$noti-shadow-2: 0 12px 28px 0 #0003, 0 2px 4px 0 #0000001a, inset 0 0 0 1px #ffffff80;
$noti-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);

$form-check-margin-bottom: 0 !default;

@mixin text-ellipsis {
	overflow: hidden;
	word-break: break-all;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// default flag-icon
.fla {
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ccc' d='M0 0h512v512H0z'/%3E%3Ctext fill='%23999' x='256' y='384' style='font: bold 333px sans-serif' text-anchor='middle'%3E%3F%3C/text%3E%3C/svg%3E");
	border-radius: 4px;
	background-size: cover;
	.flag-icon {
		background-color: #d2d2d2;
		background-size: cover;
		margin-top: 0;
		line-height: unset;
	}
}

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Configuration

@import 'functions';
@import 'variables';

@import 'mixins';
@import 'utilities';

// Layout & components

:root {
	// Custom variable values only support SassScript inside `#{}`.
	@each $color, $value in $colors {
		--#{$color}: #{$value};
	}

	@each $color, $value in $theme-colors {
		--#{$color}: #{$value};
	}

	// Use `inspect` for lists so that quoted items keep the quotes.
	// See https://github.com/sass/sass/issues/2383#issuecomment-336349172
	--font-family-sans-serif: #{inspect($font-family-sans-serif)};
	--font-family-monospace: #{inspect($font-family-monospace)};
	scrollbar-color: #bbb #eee;
}

@import 'reboot';
@import 'tables';
@import 'forms';
@import 'buttons';
@import 'button-group';
@import 'badge';
@import 'tooltip';
@import 'text';
@import 'sizing';
@import 'progress';

// Subiz styles
@import 'convos';
@import 'main_layout';
@import 'scrollbar';
@import 'editor';
@import 'message';
@import 'spinner';
@import 'switch';
@import 'radio';
@import 'editablelabel';
@import 'user_info';
@import 'onboarding';
@import 'convo';
@import 'emoji';
@import 'modal';
@import 'tag';
@import 'dropdown';
@import 'toaster';
@import 'avatar_group';
@import 'loading';
@import 'input_search';
@import 'tab';
@import 'live';
@import 'report';
@import 'auth';
@import 'search';
@import 'campaign';
@import 'link';
@import 'confirm_popup';
@import 'setting';
@import 'instant_noti';
@import 'payment';
@import 'rule';
@import 'agents_picker';
@import 'channel_picker';
@import 'location_picker';
@import 'lead';
@import 'error_page';
@import 'pagination';
@import 'bot';
@import 'agent';
@import 'drawer';
@import 'button-switch';
@import 'widget';
@import 'web_plugin';
@import 'i18n_text';
@import 'metric';
@import 'user_label';
@import 'product';
@import 'order';
@import 'tag_input';
@import '_multirange.css';
@import 'date_range_picker';
@import 'pipeline';
@import 'call_center';
@import 'audio_player';
@import 'call_modal';
@import 'task';
@import 'segment';
@import 'lexical';
@import 'ticket';
@import 'lightgallery/css/lightgallery-bundle.css';
@import 'knowledge_base';
@import 'referral';
@import '_invite_agent';
@import '_sign_in';
@import 'accmgr';
@import '_promotion_program';
@import 'automation';

.box {
	position: relative;
	border: 2px solid $gray-500;
	border-radius: 5px;
	padding: $spacer * 2;
	margin-bottom: $spacer;
}

.w-100 {
	width: 100%;
}

.flex__1 {
	flex: 1;
}

.flex__2 {
	flex: 2;
}

.feather {
	flex-shrink: 0;
}

.flag-icon {
	border-radius: 3px;
	margin-top: -2px;
	flex-shrink: 0;
}

.overflow__auto {
	overflow: auto;
}

.d__none {
	display: none !important;
}

.cursor__pointer {
	cursor: pointer;
}

.form-group {
	margin-bottom: 1.5rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.tag {
	display: inline-block;
	padding: 0 0.5rem;
	border-radius: 4px;
	line-height: 1.5;
	font-size: 13px;
	background: #ffffff;
	border: 1px solid transparent;
	white-space: nowrap;
	color: #555;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.tag.tag--primary {
	color: #fff;
	background-color: $primary;
	border-color: $primary;
}

.tag.tag--warning {
	background: $warning;
	border-color: $warning;
}

.tag.tag--success {
	background: $success;
	border-color: $success;
	color: #333;
}

.tag.tag--danger {
	background: $danger;
	border-color: $danger;
	color: #fff;
}

.tag.tag--primary.tag--light {
	color: rgb(41, 116, 249);
	background: rgb(238, 244, 255);
	border-color: rgb(191, 213, 253);
}

.tag.tag--warning.tag--light {
	color: rgb(253, 174, 4);
	background: rgb(255, 249, 235);
	border-color: rgb(254, 231, 180);
}

.tag.tag--success.tag--light {
	color: rgb(3, 137, 47);
	background: rgb(235, 246, 238);
	border-color: rgb(179, 220, 193);
}

.tag.tag--danger.tag--light {
	color: rgb(252, 13, 6);
	background: rgb(255, 236, 235);
	border-color: rgb(254, 182, 180);
}

.cursor-initial {
	cursor: initial;
}

.card {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid $border-color;
	border-radius: 0.25rem;
}

.card-body {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.25rem;
}

.page-header {
	display: flex;
	margin-bottom: $spacer * 2;
	align-items: center;
	flex-wrap: nowrap;

	&.page-header--mb-1 {
		margin-bottom: math.div($spacer, 4);
	}

	&.page-header--mb-2 {
		margin-bottom: math.div($spacer, 2);
	}

	&.page-header--mb-3 {
		margin-bottom: $spacer;
	}
}

.page-header__left {
	flex: 1;
	max-width: 70%;
}

.page-header__right {
	display: flex;
	margin-left: auto;
	align-items: center;
}

.page-header-title {
	font-weight: 500;
	line-height: 1.25;
	font-size: 1.5rem;
	color: $black;
	&.title--sm {
		font-size: 1.125rem;
		line-height: 1;
		margin-bottom: 0;
	}
}

.page-header-subtitle {
	color: $gray-700;
	margin-bottom: $spacer * 2;
}

// delete icon
.x-icon {
	cursor: pointer;
	stroke: $gray-500;
	color: $gray-500;

	&:hover {
		stroke: $gray-700;
		color: $gray-700;
	}
}

// Modal
.modal__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: $spacer;
	border-bottom: solid 1px $border-color;
}

.modal__body {
	padding-top: $spacer * 2;
}

.modal__footer {
	margin-top: $spacer * 2;
	text-align: right;
}

// Popover
.popover {
	box-shadow: $box-shadow;
	border-radius: $spacer;
	z-index: 10003;
}
.popover_inner {
	background: $white;
	position: relative;
	z-index: 1;
	border-radius: $spacer;
}
.popover::after {
	content: '';
	display: block;
	width: 18px;
	height: 18px;
	position: absolute;
	transform: translate(0, -50%) rotate(45deg);
	background-color: $white;
	box-shadow: $box-shadow;
}
.popover__header-title {
	font-size: 18px;
	font-weight: 700;
	padding: $spacer $spacer * 2;
	border-bottom: solid 1px $border-color;
}
.popover__body {
	padding: $spacer * 2;
}

.dot {
	width: 6px;
	flex-shrink: 0;
	height: 6px;
	border-radius: 50%;
	background-color: $gray-900;
	margin-top: -3px;

	&.dot--sm {
		width: 2px;
		height: 2px;
	}

	&.dot__md {
		width: 8px;
		height: 8px;
	}

	&.dot__lg {
		width: 12px;
		height: 12px;
	}
}

@each $color, $value in $theme-colors {
	.dot.dot__#{$color} {
		background-color: $value;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$spacer;
	margin-right: -$spacer;
}

.h3 {
	font-weight: 500;
	font-size: 16px;
}

.h4 {
	font-size: 15px;
}

.uploadable-image__wrapper {
	position: relative;
	border-radius: 2px;
	overflow: hidden;
	display: inline-block;

	&:hover .uploadable-image__content {
		opacity: 1;
	}
}

.uploadable-image__content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	background-color: rgba(0, 0, 0, 0.55);
	font-size: 12px;
	opacity: 0;
	transition: 0.3s;
	cursor: pointer;
}

.uploadable-image__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.65);
}

.upload-zone {
	border: 2px dashed $border-color;
	border-radius: 8px;
	min-height: 300px;
	max-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $white;
	cursor: pointer;

	&:hover {
		background-color: $gray-100;
	}
}

.flag-icon.flag-icon--full {
	border-radius: 0;
}

.bounce-enter-active {
	animation: bounce-in 0.5s;
}
.bounce-leave-active {
	animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

.blinking__slowly {
	animation: blinker 1500ms ease-out infinite;
}

.blinking {
	animation: blinker 1s linear infinite;
}

@keyframes blinker {
	0% {
		opacity: 1;
	}
	60% {
		opacity: 0.9;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0.8;
	}
}

/* The device with borders */
.smartphone {
	position: relative;
	width: 400px;
	height: 720px;
	margin: auto;
	border: 16px black solid;
	border-top-width: 60px;
	border-bottom-width: 60px;
	border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
	content: '';
	display: block;
	width: 60px;
	height: 5px;
	position: absolute;
	top: -30px;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #333;
	border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
	content: '';
	display: block;
	width: 35px;
	height: 35px;
	position: absolute;
	left: 50%;
	bottom: -65px;
	transform: translate(-50%, -50%);
	background: #333;
	border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone__content {
	position: absolute;
	left: 0;
	top: 0;
	width: 370px;
	height: 600px;
}

.select_lang {
	height: 27px;
	color: #777;
	border: 1px solid #6c757d;
	border-radius: 4px;
	padding: 2px 7px;
	font-size: 12px;
	&:hover {
		color: #444;
		border: 1px solid #555;
	}
}

.clickable {
	cursor: pointer;
	&.unclickable {
		cursor: auto;
	}
}

.access_token {
	margin-top: 5px;
	padding: 5px 10px;
	background: #dfecff;
	border-radius: 5px;
	word-break: break-word;

	font-family: $font-family-monospace;
}

.mono {
	font-family: $font-family-monospace;
}

.star-icon {
	stroke: #999;
	cursor: pointer;

	&.active {
		stroke: #ffc107;
		cursor: initial;

		path:last-child {
			fill: #ffeb3b;
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.image2 {
	position: relative;
	&:hover {
		.image2__overlay {
			opacity: 1;
		}
	}
}

.image2__overlay {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #00000024;
}

.no-shrink {
	flex-shrink: 0;
}

.undefined {
	color: rgb(192, 192, 192);
	font-style: italic;
}

.dot-typing {
	position: relative;
	left: -9983px;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background-color: #6c757d;
	color: #6c757d;
	box-shadow: 9984px 0 0 0 #6c757d, 9991px 0 0 0 #aca5ad, 9998px 0 0 0 #ccc5cd;
}

.convo_modal_key {
	link-height: 1;
	font-size: 14px;
	padding: 3px 8px;
	background-color: $gray-200;
	border-radius: 5px;
	font-family: $font-family-monospace;
	display: inline-block;
}

.session__header {
	margin-top: 10px;
	font-size: 16px;
	font-weight: 500;
	color: black;
}

.section_label {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 5px;
	margin-top: 10px;
}

.icon-filled {
	path:last-child {
		fill: currentColor;
	}
}

#webpack-dev-server-client-overlay {
	display: none;
}

.sbz-time-picker-dropdown.dropdown {
	max-width: unset;
	max-height: unset;
	min-width: unset;
	min-height: unset;
	top: 0;
	border-radius: 4px;
}

.sbz-time-picker-dropdown-content {
	display: flex;
}

.sbz-time-picker-selection {
	flex: 1;
	width: 72px;
	height: 240px;
	overflow-y: scroll;
	overflow-x: hidden;
	border-right: 1px solid $border-color;

	&:last-child {
		border-right: none;
	}
}

.sbz-time-picker-selection-item {
	margin: 3px;
	padding-top: 4px;
	padding-bottom: 4px;
	font-size: 14px;
	cursor: pointer;
	border-radius: 4px;
	text-align: center;
	&:hover {
		background-color: $gray-200;
	}
	&.active {
		background-color: #e6f4ff;
	}
	&.empty {
		color: transparent;
		cursor: auto;
	}
}

.sbz-time-picker-dropdown-footer {
	border-top: 1px solid $border-color;
	padding: 6px 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sbz-time-input-wrapper {
	position: relative;
	width: 140px;

	.sbz-time-input {
		padding-right: 40px;
	}

	select.sbz-time-input {
		apperance: none;
	}
}

.sbz-time-input.form-control.is-invalid {
	background-image: none;

	& + .sbz-time-input-icon {
		color: $danger;
	}
}

.sbz-time-input-icon {
	position: absolute;
	width: 18px;
	height: 18px;
	right: 5px;
	top: calc(50% - 9px);
}

.sbz_box {
	border-radius: 8px;
	border: 1px solid $border-color;
	background-color: $white;
}

.sbz_radio_box {
	@extend.sbz_box;
	cursor: pointer;
	padding: 8px 15px;
	display: flex;
	position: relative;
	&.disabled {
		background-color: $gray-100;
		cursor: auto;
		opacity: 0.6;
	}

	&:hover {
		background-color: $gray-100;
	}

	&.active {
		border-color: $primary;
		box-shadow: inset 0 0 0 1px $primary;
		background-color: #eff8ff;
	}

	input[type='radio'] {
		//position: relative;
		//top: 2px;
		flex-shrink: 0;
	}
}

.sbz_box_item {
	padding: 5px 10px;
	border-bottom: 1px solid $border-color;

	&:last-child {
		border-bottom: none;
	}
}

.recent_users {
	right: $spacer;
	height: 100%;
	bottom: $spacer * 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: $white;
	border-left: 1px solid $section-border-color;
	width: 50px;
	align-items: center;
	padding-bottom: 20px;
	z-index: $zindex-recent-users;
}

.line-height-0 {
	line-height: 0;
}

.single_node {
	width: 100%;
	padding: 5px 0;

	&.dragenter {
		background: $gray-200;
	}
}

.node_zone_before {
	height: 0px;

	&.dragenter {
		background: red;
	}
}

.node_zone_after {
	height: 0px;
	&.dragenter {
		background: red;
	}
}

.test_list.dragging {
	.single_node {
		padding-top: 0;
		padding-bottom: 0;

		&:first-child {
			padding-top: 5px;
		}
	}

	.node_zone_before {
		height: 5px;
	}
	.node_zone_after {
		height: 5px;
	}
}

.line_2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.line_3 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.hover_visible_wrapper:hover .hover_visible_item {
	visibility: visible;
}

.hover_visible_item {
	visibility: hidden;
}

.border_left {
	border-left: 1px solid $border-color;
}
.border_right {
	border-right: 1px solid $border-color;
}
.border_top {
	border-top: 1px solid $border-color;
}
.border_bottom {
	border-bottom: 1px solid $border-color;
}
.border_all {
	border: 1px solid $border-color;
}

.test_drag_item {
  width: 250px;
  background: white;
  border-radius: 8px;
  border: 1px solid $border-color;
  padding: 4px 10px;
  margin-bottom: 10px;

  &.dragging {
    background: whitesmoke;
    color: whitesmoke;
  }
}
